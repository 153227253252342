import AddIcon from '@mui/icons-material/Add';
import GavelIcon from '@mui/icons-material/Gavel';
import { Box, Container, Fab, Grid, List, ListItem, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import './App.css';
import './Home.css';
import LawAppBar from './LawAppBar';

export default function Home() {
    const [cookies, setCookie, removeCookie] = useCookies(['given_name', 'userid', 'picture', 'email', 'credential']);

    const [cases, setCases] = useState({});

    useEffect(() => {
        if (cookies.credential && cookies.userid) {
            fetch('/api/cases', {
                method: 'GET',
                credentials: 'same-origin'
            })
                .then(res => res.json())
                .then(json => {
                    setCases(json['cases']);
                })
                .catch(err => { console.log('Unable to load cases: ' + err); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();
    const onCaseSelected = function (caseid) {
        navigate('/case/' + caseid);
    }

    const caseItems = Object.keys(cases).map(caseid =>
        <ListItem key={caseid} onClick={() => onCaseSelected(caseid)} className="case-item">
            <ListItemButton>
                <GavelIcon className="icon-style" />
                <ListItemText primary={cases[caseid]['name'] || caseid} />
            </ListItemButton>
        </ListItem>
    );

    const hasCases = cookies.credential && cookies.userid && Object.keys(cases).length > 0;

    return (
        <div className="App">
            <LawAppBar cookies={cookies} />
            {hasCases ?
                (
                    <div className="case-container" style={{
                        maxWidth: "800px",
                        margin: "20px auto 20px auto",
                        padding: "20px"
                    }}>
                        <Grid container spacing={0} columns={3}>
                            <Grid item xs={1} style={{ textAlign: "left" }}>
                                <Fab variant="extended" size="medium" color="primary"
                                    onClick={() => navigate('/case/new')}
                                    style={{
                                        top: "-4px"
                                    }}>
                                    <AddIcon sx={{ mr: 0 }} />
                                    New Case
                                </Fab>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="h6" className="title" style={{ padding: "0 0 15px" }}>
                                    My Cases
                                </Typography>
                            </Grid>
                        </Grid>
                        <List className="list-style">
                            {caseItems}
                        </List>
                    </div>
                ) :
                (
                    < Container style={{ width: "100%", maxWidth: "100%" }}>
                        <Box my={4} className="welcome-section">
                            <Typography variant="h4" component="h1" gutterBottom>
                                Automated Document Review for Personal Injury Law
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom style={{ color: "#666", fontSize: "1.1em" }}>
                                Upload case documents to extract evidence of negligence, tabulate medical expenses, categorize, summarize and more.
                                {/* Organize case documents, extract legally relevant information, and perform custom analysis in seconds */}
                            </Typography>
                            <Fab variant="extended" size="large" color="primary"
                                onClick={() => navigate('/case/new')}>
                                <GavelIcon sx={{ mr: 0 }} />
                                Try It Now
                            </Fab>
                        </Box>
                        <Paper elevation={3} style={{ display: 'inline-block', borderRadius: "10px", marginBottom: "50px" }}>
                            <video width="640" height="480" className="demo-video" src="/demo.webm" preload="auto" controls playsInline autoPlay muted loop />
                        </Paper>
                    </Container>
                )
            }
        </div >
    );
}