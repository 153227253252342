import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { createRef } from 'react';
import CopyToClipboardButton from './CopyToClipboardButton.js';
export default function MedicalExpenses({ caseInfo, fileNamesToFilePropertiesMap, displayedDocument, setDisplayedDocument, setSnackBarMessage }) {
    const tableRef = createRef();
    var documentNamesToDisplay = caseInfo !== null && 'documents' in caseInfo ?
        Object.keys(caseInfo['documents']).filter(filename => fileNamesToFilePropertiesMap[filename]['selected']) :
        [];
    var medicalExpenses = documentNamesToDisplay.map(documentName => {
        caseInfo['documents'][documentName]['analysis']?.['medical_expenses']?.forEach((medicalExpense, i) => {
            medicalExpense['id'] = documentName + i; // Used in UI as keys for use by React framework
            medicalExpense['source_filename'] = documentName; // File used by SourceDocumentViewer
        });
        return caseInfo['documents'][documentName]['analysis']?.['medical_expenses'];
    })
        .filter(medicalExpenseArr => medicalExpenseArr) // the ?. above can cause null entries; filter them
        .reduce((accumulator, medicalExpenseArr) => accumulator.concat(medicalExpenseArr), [])
        .filter(medicalExpense => isValidMedicalExpenseItem(medicalExpense)) // Remove '', 'N/A', null, etc
        .sort((expense1, expense2) => Date.parse(expense1.treatment_date) - Date.parse(expense2.treatment_date));

    if (medicalExpenses.length === 0) {
        return (<Typography fontSize="1.2em" fontWeight={200} sx={{ marginTop: "100px", marginBottom: "500px" }}>
            Medical charges will be automatically extracted from uploaded medical bills, invoices, etc and listed here.
        </Typography>)
    }

    var tableBodyRows = medicalExpenses
        .map(medicalExpense => (
            <TableRow
                key={medicalExpense['id']}
                onClick={() => {
                    setDisplayedDocument({ 'name': medicalExpense['source_filename'], 'page': medicalExpense['page'] })
                }}
                className={medicalExpense['source_filename'] === displayedDocument?.['name'] ? "selected" : ""} >
                <TableCell> {medicalExpense.treatment_date || ''}</TableCell>
                <TableCell> {medicalExpense.patient || ''}</TableCell>
                <TableCell> {medicalExpense.treatment || ''}</TableCell>
                <TableCell> {medicalExpense.payee || ''}</TableCell>
                <TableCell> {medicalExpense.insurance || ''}</TableCell>
                {/* <TableCell> {medicalExpense.currency_code || ''}</TableCell> */}
                <TableCell> {Number(medicalExpense.total_charge)?.toFixed(2).toLocaleString(navigator.language) || ''}</TableCell>
                <TableCell> {Number(medicalExpense.total_adjustment)?.toFixed(2).toLocaleString(navigator.language) || ''}</TableCell>
                <TableCell> {Number(medicalExpense.total_balance)?.toFixed(2).toLocaleString(navigator.language) || ''}</TableCell>
                <TableCell> {medicalExpense['source_filename']} </TableCell>
                <TableCell> {medicalExpense.page || ''}</TableCell>
            </TableRow>));
    // patient: Name of the patient that received the treatment or service,\
    // insurance: Name of ths insurance company,\
    // payee: Name of the merchant, facility, or provider that provided the treatment or service,\
    // document date: date the document was created,\
    // treatment date: date that treatment or service was provided,\
    // total charge: (number) total charge or cost,\
    // total adjustment: (number) total adjustments or deductions,\
    // total paid: (number) total amount that has been paid,\
    // total balance: (number) total outstanding amount,\
    // currency code: (text) 3 letter ISO 4217 currency code of the total balance,\
    // page: page number on which the expense was found\
    return (
        <div>
            <div style={{ display: "flex" }}>
                <CopyToClipboardButton nodeRef={tableRef} setSnackBarMessage={setSnackBarMessage}></CopyToClipboardButton>
            </div>
            <Table ref={tableRef} sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ minWidth: "150px" }}>Treatment Date</TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell>Treatment/Service</TableCell>
                        <TableCell>Provider/Hospital</TableCell>
                        <TableCell>Insurance</TableCell>
                        {/* <TableCell>Currency code</TableCell> */}
                        <TableCell>Total Charge</TableCell>
                        <TableCell>Total Adjustments/Payments</TableCell>
                        <TableCell>Total Balance</TableCell>
                        <TableCell>Document</TableCell>
                        <TableCell style={{ minWidth: "150px" }}>Page</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBodyRows}
                </TableBody>
            </Table>
        </div>
    );

    function isValidMedicalExpenseItem(medicalExpense) {
        return medicalExpense['total_charge'];
    }

}
