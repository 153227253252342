import { Drawer } from '@mui/material';
import * as React from 'react';


export default function SourceDocumentViewer({ caseInfo, displayedDocument, sourceDocumentViewerOnClose }) {
    return (
        <Drawer
            anchor="right"
            open={displayedDocument !== null}
            onClose={() => sourceDocumentViewerOnClose()}
            PaperProps={{
                sx: {
                    width: 900,
                    maxWidth: "90%"
                }
            }}
        >
            <iframe
                style={{ "height": "100%", "width": "100%" }}
                title={"Source Document"}
                // This check ensures that file has been copied to the location from which it is served. Otherwise client will get 404 error which is cached by browser.
                src={displayedDocument !== null && ('pdf' in caseInfo['documents']?.[displayedDocument['name']] || 'txt' in caseInfo['documents']?.[displayedDocument['name']]) ? "/api/documents/" + caseInfo['caseId'] + '/' + displayedDocument['name'] + '#page=' + displayedDocument['page'] : '/document_processing'} />
        </Drawer >)
}
