
import './App.css';

export default function DocumentProcessing() {
    return <div className="App" style={{ marginTop: "200px" }} >
        <img src="/loading.gif" alt="loading..."
            title="Processing..."
            style={{
                marginRight: "5px",
                height: "20px",
                verticalAlign: "middle",
            }} />
        Document is being processed...please wait
    </div >
}