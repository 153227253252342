import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from "@mui/material";

/**
 * Displays a Copy to Clipboard button which when clicked, will copy the contents
 * of the given DOM node to clipboard and display a message on snackBar.
 * @param nodeRef ref to the DOM node whose contents should be copied to clipboard.
 * @param setSnackBarMessage method to set a message to appear in the snack bar.
 */
export default function CopyToClipboardButton({ nodeRef, setSnackBarMessage }) {
    return (
        <Button size="small" variant="text" onClick={() => {
            let range, sel;
            // Ensure that range and selection are supported by the browsers
            if (document.createRange && window.getSelection) {
                range = document.createRange();
                sel = window.getSelection();
                // unselect any element in the page
                sel.removeAllRanges();

                try {
                    range.selectNodeContents(nodeRef.current);
                    sel.addRange(range);
                } catch (e) {
                    range.selectNode(nodeRef.current);
                    sel.addRange(range);
                }
                // While execCommand is deprecated, it has support on all browsers. 
                // The Clipboard API is currently not supported on Firefox.
                document.execCommand('copy');
                sel.removeAllRanges();

                setSnackBarMessage('Copied to Clipboard');
            } else {
                setSnackBarMessage('Error: Copy to clipboard not supported by browser.')
            }
        }}><ContentCopyIcon></ContentCopyIcon>Copy table to clipboard</Button>);
}