import { Button, Snackbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import './App.css';
import './Case.css';
import CaseSummaryHeader from './CaseSummaryHeader';
import Discovery from './Discovery.js';
import DocumentsOverview from './DocumentsOverview';
import LawAppBar from './LawAppBar';
import MedicalExpenses from './MedicalExpenses.js';
import SourceDocumentViewer from './SourceDocumentViewer.js';
import TimelineView from './TimelineView.js';

function CustomTabPanel(props) {
    const { children, selectedTabValue, myValue, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={selectedTabValue !== myValue}
            id={`simple-tabpanel-${myValue}`}
            aria-labelledby={`simple-tab-${myValue}`}
            {...other}
        >
            {selectedTabValue === myValue && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
function Case() {
    const { caseId } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['given_name', 'userid', 'picture', 'email', 'credential']);
    // server side state
    const [caseInfo, setCaseInfo] = useState(null);

    // client side state
    const [snackbarMessage, setSnackBarMessage] = useState(null);
    // Contains fields 'color', 'selected', and 'status'.
    const [fileNamesToFilePropertiesMap, setFileNamesToFilePropertiesMap] = useState({});
    const refFileNamesToFilePropertiesMap = useRef();
    // Without using reference, value within fetchCaseInfoAndUpdateUI becomes stale when state
    // is updated from .
    refFileNamesToFilePropertiesMap.current = fileNamesToFilePropertiesMap;

    // Map with filenames that are being uploaded and state has not reached backend DB.
    // Key is filename, value is timestamp until which we wait for 'processing'status to reach DB.
    // We keep uploaded files in this set until the timestamp is reached or until DB status is processing, whichever occurs first.
    const [uploadingFileNamesSet, setUploadingFileNamesSet] = useState({});
    // Index of timeline event that was selected by user(if any)
    const [selectedEventIndex, setSelectedEventIndex] = useState(-1);
    // Name of document that is being displayed in DocumentSourceViewer, if any.
    const [displayedDocument, setDisplayedDocument] = useState(null);
    const [selectedTabValue, setSelectedTabValue] = useState(0);
    const navigate = useNavigate();
    var colorIndex = 0;
    const colors = ['#FF000020', '#50D0D060', '#2F4F4F50', '#D0D05090', '#5050D040', '#50D05070', '#D0505050', '#50505050', '#D050D030'];

    useEffect(() => {
        if (cookies.credential && cookies.userid) {
            //TODO: Call server to get all details for {caseid}
            fetchCaseInfoAndUpdateUI();
            return;
        }
        console.log('Auth credentials not found. Calling Google API');
        renderGoogleLoginButton();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log('Rendering lawapp');
    if (cookies.credential === undefined || cookies.credential === null) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: '100vh',
            }}
            >
                <Typography variant="h6">Please sign-in to continue</Typography>
                <Button component="div" id="googleLogin">Login</Button>
                <Typography variant="caption" component="div" style={{ margin: "50px" }}>Login to ensure secure access to case documents</Typography>
            </div >)
    } else if (caseInfo === null) {
        return <img src="/loading.gif" alt="loading..." style={{ height: "50px", position: "absolute", top: "30%", left: "50%" }} />;
    }

    return (
        <div className="App">
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: "center" }}
                open={snackbarMessage !== null}
                autoHideDuration={3000}
                onClose={() => setSnackBarMessage(null)}
                message={snackbarMessage}
            />
            <LawAppBar />
            <CaseSummaryHeader
                caseInfo={caseInfo}
                setCaseInfo={setCaseInfo}
                refFileNamesToFilePropertiesMap={refFileNamesToFilePropertiesMap}
                setFileNamesToFilePropertiesMap={setFileNamesToFilePropertiesMap}
                uploadingFileNamesSet={uploadingFileNamesSet}
                onFilesSelectedForUpload={onFilesSelectedForUpload}
                setSnackBarMessage={setSnackBarMessage}></CaseSummaryHeader>

            <Box sx={{ width: '100%', visibility: Object.keys(caseInfo['documents']).length > 0 ? "visible" : "hidden" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable"
                        scrollButtons="auto"
                        value={selectedTabValue}
                        onChange={handleTabChange}
                        aria-label="Information from selected documents">
                        <Tab label="Documents Overview" />
                        <Tab label="Timeline of Events" />
                        <Tab label="Medical Expenses" />
                        {PERSONAL_INJURY_CASE_TYPES.includes((caseInfo['case_type'] || '').toLowerCase()) && <Tab label="Discovery" />}
                    </Tabs>
                </Box>
                <CustomTabPanel selectedTabValue={selectedTabValue} myValue={0}>
                    <DocumentsOverview caseInfo={caseInfo}
                        fileNamesToFilePropertiesMap={fileNamesToFilePropertiesMap}
                        displayedDocumentName={displayedDocument}
                        setDisplayedDocument={setDisplayedDocument}
                        setSnackBarMessage={setSnackBarMessage} />
                </CustomTabPanel>
                <CustomTabPanel selectedTabValue={selectedTabValue} myValue={1}>
                    <TimelineView caseInfo={caseInfo}
                        fileNamesToFilePropertiesMap={fileNamesToFilePropertiesMap}
                        displayedDocumentName={displayedDocument}
                        setDisplayedDocument={setDisplayedDocument}
                        setSnackBarMessage={setSnackBarMessage}
                    />
                </CustomTabPanel>
                <CustomTabPanel selectedTabValue={selectedTabValue} myValue={2}>
                    <MedicalExpenses caseInfo={caseInfo}
                        fileNamesToFilePropertiesMap={fileNamesToFilePropertiesMap}
                        displayedDocumentName={displayedDocument}
                        setDisplayedDocument={setDisplayedDocument}
                        setSnackBarMessage={setSnackBarMessage}
                    />
                </CustomTabPanel>
                {PERSONAL_INJURY_CASE_TYPES.includes((caseInfo['case_type'] || '').toLowerCase()) && <CustomTabPanel selectedTabValue={selectedTabValue} myValue={3}>
                    <Discovery caseInfo={caseInfo}
                        fileNamesToFilePropertiesMap={fileNamesToFilePropertiesMap}
                        displayedDocumentName={displayedDocument}
                        setDisplayedDocument={setDisplayedDocument}
                        setSnackBarMessage={setSnackBarMessage}
                    />
                </CustomTabPanel>}
            </Box>
            <SourceDocumentViewer caseInfo={caseInfo}
                displayedDocument={displayedDocument}
                sourceDocumentViewerOnClose={sourceDocumentViewerOnClose} />
        </div >
    );

    function renderGoogleLoginButton() {
        /*** Handle auth */
        try {
            window.google.accounts.id.initialize({
                client_id:
                    "724405183597-qqmqnhdi2k7qd5m0u2de1b069etv333j.apps.googleusercontent.com",
                callback: onGoogleLoginSuccess,
            });

            window.google.accounts.id.renderButton(document.getElementById("googleLogin"), {
                theme: "outline",
                size: "large",
                text: "sign_in",
            });

            window.google.accounts.id.prompt();
        } catch (e) {
            console.log("Error", e);
            // TODO: Send errors to cloud
        }
        /*** End Auth */
    }

    function onGoogleLoginSuccess(response) {
        const loginInfo = decodeJwtResponseFromGoogleAPI(response['credential']);
        setCookie('given_name', loginInfo['given_name'], { path: '/' });
        setCookie('email', loginInfo['email'], { path: '/' });
        setCookie('userid', loginInfo['sub'], { path: '/' });
        setCookie('picture', loginInfo['picture'], { path: '/' });
        setCookie('credential', response['credential'], { path: '/' });

        console.log(response);
        console.log('LoginInfo: ' + JSON.stringify(loginInfo));
        console.log('Cookie: ' + cookies.username);
        fetchCaseInfoAndUpdateUI();
    }
    function decodeJwtResponseFromGoogleAPI(token) {
        let base64Url = token.split('.')[1]
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload =
            decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' +
                    c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        return JSON.parse(jsonPayload)
    }

    function logout() {
        console.log('Removing cookies');
        removeCookie('given_name', { path: '/' });
        removeCookie('userid', { path: '/' });
        removeCookie('email', { path: '/' });
        removeCookie('picture', { path: '/' });
        removeCookie('credential', { path: '/' });
        console.log('After removing Cookie: ' + cookies.credential);
        setTimeout(renderGoogleLoginButton);
    }

    function handleTabChange(event, newValue) {
        setSelectedTabValue(newValue);
    }

    function sourceDocumentViewerOnClose() {
        setSelectedEventIndex(-1);
        setDisplayedDocument(null);
    }

    function fetchCaseInfoAndUpdateUI() {
        console.log('fetchCaseInfoAndUpdateUI calling: /api/case/' + caseId);

        fetch('/api/case/' + caseId, {
            method: 'GET',
            credentials: 'same-origin'
        })
            .then(res => {
                if (res.status === 401) {
                    logout();
                    return "{}";
                }
                return res.json();
            })
            .then(json => {
                let newCaseInfo = json['case'];

                if (newCaseInfo) {
                    updateUIWithNewCaseInfo(newCaseInfo);
                } else {
                    console.log('FATAL: No caseInfo in response:' + json);
                }
            })
            .catch(err => {
                var data = {
                    err: err,
                    stack: err.stack,
                    context: 'fetchCaseInfoAndUpdateUI'
                }
                fetch('/api/jserrors', {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: new Headers({ 'content-type': 'application/json' }),
                    body: JSON.stringify(data)
                });
                console.log('[FATAL]Unable to load case: ' + JSON.stringify(data));
            });
    }

    function updateUIWithNewCaseInfo(newCaseInfo) {
        console.log('In updateUIWithNewCaseInfo');
        if (caseInfo && newCaseInfo['last_updated'] <= caseInfo['last_updated']) {
            console.log('Ignoring new CaseInfo since it is stale');
            return;
        }
        if (caseId === 'new' &&
            /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(newCaseInfo['caseId'])) {
            navigate('/case/' + newCaseInfo['caseId']);
            console.log('Navigating to URL with caseId from /new');
        }
        const newFileNamesToFilePropertiesMap = {};
        colorIndex = 0;
        let hasDocsInProcessingStatus = false;
        for (let filename in newCaseInfo['documents']) {
            newFileNamesToFilePropertiesMap[filename] = {
                color: colors[colorIndex++],
                selected: (filename in refFileNamesToFilePropertiesMap.current) ? refFileNamesToFilePropertiesMap.current[filename]['selected'] : true,
                status: newCaseInfo['documents'][filename]['status']
            }
            hasDocsInProcessingStatus = hasDocsInProcessingStatus || newCaseInfo['documents'][filename]['status']?.includes('processing');
        }

        const newUploadingFileNamesSet = {};
        for (let filename in uploadingFileNamesSet) {
            hasDocsInProcessingStatus = true;
            // Only keep documents in newUploadingFileNamesSet if
            //    it is in DB, but status has not transitioned to 'processing' within allocated time
            if (!(filename in newFileNamesToFilePropertiesMap) || // file is not in DB 
                (!newFileNamesToFilePropertiesMap[filename]['status'].includes('processing') && // status is not 'processing'
                    uploadingFileNamesSet[filename] > new Date().getTime())) { // within allocated time to reach 'processing'
                newUploadingFileNamesSet[filename] = uploadingFileNamesSet[filename];

                newFileNamesToFilePropertiesMap[filename] = {
                    color: colors[colorIndex++],
                    selected: (filename in refFileNamesToFilePropertiesMap.current) ? refFileNamesToFilePropertiesMap.current[filename]['selected'] : true,
                    status: 'processing_ocr'
                }
            }
        }
        setCaseInfo(newCaseInfo);
        setFileNamesToFilePropertiesMap(newFileNamesToFilePropertiesMap);
        setUploadingFileNamesSet(newUploadingFileNamesSet);
        console.log('New Case files: ' + Object.keys(newCaseInfo['documents']).join(', '));
        console.log('Uploading files: ' + Object.keys(newUploadingFileNamesSet).join(', '));

        if (hasDocsInProcessingStatus) {
            setTimeout(fetchCaseInfoAndUpdateUI, 10000);
        }
    }

    async function onFilesSelectedForUpload(file) {
        console.log('onFilesSelectedForUpload: ' + file[0].name);
        let data = new FormData();
        let newFileNamesToFilePropertiesMap = { ...fileNamesToFilePropertiesMap };
        let newUploadingFileNamesSet = { ...uploadingFileNamesSet };
        for (let i = 0; i < file.length; i++) {
            // prepare upload request
            data.append('file', file[i]);

            // reflect in client state
            if (!(file[i].name in newFileNamesToFilePropertiesMap)) { // first time upload
                newFileNamesToFilePropertiesMap[file[i].name] = {
                    color: colors[colorIndex++],
                    selected: true,
                    status: 'processing_ocr'
                }
                newUploadingFileNamesSet[file[i].name] = new Date().getTime();
            } else if (!newFileNamesToFilePropertiesMap[file[i].name]['status'].includes('processing')) { // re-upload same file
                newFileNamesToFilePropertiesMap[file[i].name]['status'] = 'processing_ocr';
                newUploadingFileNamesSet[file[i].name] = new Date().getTime() + 60 * 1000; // give 60 seconds for processing status to be reflected in DB
            }
        }
        setFileNamesToFilePropertiesMap(newFileNamesToFilePropertiesMap);
        setUploadingFileNamesSet(newUploadingFileNamesSet);

        let response;
        try {
            response = await fetch('/api/documents?caseId=' + caseInfo['caseId'], {
                method: 'POST',
                credentials: 'same-origin',
                body: data,
            });
        } catch (e) {
            console.log('File upload failed: ' + e);
        }
        console.log('Fileupload status: ' + response?.ok + '; Response:' + JSON.stringify(response));
        if (response?.ok) {
            setTimeout(fetchCaseInfoAndUpdateUI, 10000);
        }
    }
}

export default Case;

const PERSONAL_INJURY_CASE_TYPES = [
    'car accident',
    'truck accident',
    'motorcycle accident',
    'bicycle accident',
    'pedestrian accident',
    'boating accident',
    'bus accident',
    'auto accident',
    'train accident',
    'aircraft accident',
    'aviation accident',
    'recreational vehicle accident',
    'injuries from dui',
    'traffic accident',
    'motorcycle insurance claims',
    'insurance claims',
    'slip and fall',
    'dog bite',
    'animal attack',
    'swimming pool accident',
    'elevator accident',
    'escalator accident',
    'inadequate security',
    'negligent security',
    'fire and burn injury',
    'toxic substances exposure',
    'worker\'s compensation',
    'construction accident',
    'construction injury',
    'industrial accident',
    'medical malpractice',
    'birth injuries',
    'nursing home abuse and neglect',
    'pharmaceutical injury',
    'pain and suffering',
    'catastrophic injury',
    'wrongful death',
    'defective product',
    'dangerous drugs',
    'defective medical device',
    'sports and recreational accident',
    'product liability',
    'school and playground injury',
    'child injury',
    'food poisoning',
    'assault',
    'battery',
    'class action lawsuit',
    'brain injury',
    'traumatic brain injury',
    'spinal cord injury',
    'severe burn injury',
    'railroad accident',
    'maritime and jones act',
    'personal injury',
]