import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { createRef } from 'react';
import CopyToClipboardButton from './CopyToClipboardButton.js';
export default function TimelineView({ caseInfo, fileNamesToFilePropertiesMap, displayedDocument, setDisplayedDocument, setSnackBarMessage }) {
    const tableRef = createRef();
    var documentNamesToDisplay = caseInfo !== null && 'documents' in caseInfo ?
        Object.keys(caseInfo['documents']).filter(filename => fileNamesToFilePropertiesMap[filename]['selected']) :
        [];
    var timelineItems = documentNamesToDisplay.map(documentName => {
        caseInfo['documents'][documentName]['analysis']?.['timeline']?.forEach((timelineItem, i) => {
            timelineItem['id'] = documentName + i; // Used in UI as keys for use by React framework
            timelineItem['source_filename'] = documentName; // File used by SourceDocumentViewer
        });
        return caseInfo['documents'][documentName]['analysis']?.['timeline'];
    })
        .filter(timelineArr => timelineArr)
        .reduce((accumulator, timelineArr) => accumulator.concat(timelineArr), [])
        .sort((event1, event2) => Date.parse(event1.date) - Date.parse(event2.date));

    var tableBodyRows = timelineItems
        .map(timelineItem => (
            <TableRow
                key={timelineItem['id']}
                onClick={() => {
                    setDisplayedDocument({ 'name': timelineItem['source_filename'], 'page': timelineItem['page'] })
                }}
                className={timelineItem['source_filename'] === displayedDocument?.['name'] ? "selected" : ""} >
                <TableCell> {timelineItem.date || ''}</TableCell>
                <TableCell> {timelineItem.event || ''}</TableCell>
                <TableCell> "{timelineItem.verbatim || ''}"</TableCell>
                <TableCell> {timelineItem['source_filename']} </TableCell>
                <TableCell> {timelineItem.page || ''}</TableCell>
            </TableRow>));

    return (
        <div>
            <div style={{ display: "flex" }}>
                <CopyToClipboardButton nodeRef={tableRef} setSnackBarMessage={setSnackBarMessage}></CopyToClipboardButton>
            </div>
            <Table ref={tableRef} sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ minWidth: "150px" }}>Date</TableCell>
                        <TableCell>Event</TableCell>
                        <TableCell>Source Text</TableCell>
                        <TableCell>Document Name</TableCell>
                        <TableCell style={{ minWidth: "150px" }}>Page</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableBodyRows}
                </TableBody>
            </Table>
        </div>
    )
}
