import './App.css';

import { useEffect } from 'react';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Case from './Case.js';
import DocumentProcessing from './DocumentProcessing';
import Home from './Home.js';

function App() {

  useEffect(() => {
    // console.log('App: useEffect!');
  });

  return (


    <Router>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/case/:caseId' element={<Case />} />
        <Route exact path='/document_processing' element={<DocumentProcessing />} />
      </Routes>
    </Router>)

}


export default App;
